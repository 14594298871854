export default [
	{
		name: "Mental Health",
		value: Buffer.from(process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
		img: "brainstorm",
	},
	{
		name: "Physical Health",
		value: Buffer.from(process.env.GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE),
		img: "stetho",
	},
];
